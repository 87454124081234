import terms from 'assets/terms'
import { Accordion, Button, ButtonStyle } from 'components'
import { useTabs } from 'services'
import { ContentSignals } from 'pages/home/contents/ContentsManager'
import './OnScreenPanel.scss'

export default function OnScreenPanel() {
  const { possibleTabs, selectedTab, setSelectedTab } = useTabs()

  const { data: rightData } = ContentSignals.right

  return (
    <div className="on-screen-panel">
      {rightData.value?.type && (
      <div className="tabs flex-center">
        {possibleTabs.length ? possibleTabs.map(tab => (
          <Button
            key={tab.position}
            className={selectedTab?.position === tab.position ? 'selected' : ''}
            text={terms.Panels.Search.tabs[tab.position]}
            style={ButtonStyle.borderLess}
            onClick={() => setSelectedTab(tab)}
          />
        )) : <span className="no-tab">{terms.Panels.Search.noTab}</span>}
      </div>
      )}
      {selectedTab && (
        <>
          <Accordion title="Postes">
            Aucun poste
          </Accordion>
          <Accordion title="Zones de tronçon">
            Aucune ligne
          </Accordion>
          <Accordion title="Travaux">
            Aucun transformateur
          </Accordion>
        </>
      )}
    </div>
  )
}
