import terms from 'assets/terms'
import { useTabs } from 'services'
import { Button, ButtonStyle, Loader, TextInput } from 'components'
import { ContentSignals } from 'pages/home/contents/ContentsManager'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { SearchResults as SearchResultsType } from 'types'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { searchObjects } from 'services/search'
import SearchResults from './results/SearchResults'
import './SearchPanel.scss'

export default function SearchPanel() {
  const { possibleTabs, selectedTab, setSelectedTab } = useTabs()
  const [search, setSearch] = useState<string>('')
  const [searchPending, setSearchPending] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<SearchResultsType>({})
  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  const debouncedSearch = useCallback(
    debounce((query, bbox, type) => {
      setSearchPending(true)
      searchObjects(query, bbox, type).then(setSearchResults).then(() => setSearchPending(false))
    }, 500),
    [],
  )

  useEffect(() => {
    if (!(selectedTab?.position === 'left') || search.length < 3) return
    const { type, bbox } = leftData.value
    if (!bbox) return
    if (type !== 'geo' && type !== 'sch') return
    debouncedSearch(search, bbox, type)
  }, [leftData.value?.vp, search, selectedTab?.position])

  useEffect(() => {
    if (!(selectedTab?.position === 'right') || search.length < 3) return
    const { type, bbox } = rightData.value
    if (!bbox) return
    if (type !== 'geo' && type !== 'sch') return
    debouncedSearch(search, bbox, type)
  }, [rightData.value?.vp, search, selectedTab?.position])

  return (
    <div className="search-panel">
      {rightData.value?.type && (
      <div className="tabs flex-center">
        {possibleTabs.length ? possibleTabs.map(tab => (
          <Button
            key={tab.position}
            className={selectedTab?.position === tab.position ? 'selected' : ''}
            text={terms.Panels.Search.tabs[tab.position]}
            style={ButtonStyle.borderLess}
            onClick={() => setSelectedTab(tab)}
          />
        )) : <span className="no-tab">{terms.Panels.Search.noTab}</span>}
      </div>
      )}
      {selectedTab && (
        <TextInput
          label={`${terms.Common.search} une IS`}
          onChange={setSearch}
          bindedValue={search}
          LeftIcon={<SearchIcon />}
          RightIcon={search && searchPending && <Loader variant="x-small" />}
        />
      )}
      <SearchResults results={searchResults} />
    </div>
  )
}
