import { Signal } from '@preact/signals-react'
import { Accordion } from 'components'
import { ContentType, SearchResult, SearchResults as SearchResultsType } from 'types'
import { calculateGeometryViewport, SOURCE_NAMES } from 'services'
import { ContentSignals } from 'pages/home/contents/ContentsManager'
import { MapDataSignal } from '../../PanelsManager'
import './SearchResults.scss'

interface Props {
  results: SearchResultsType
}

const renderResult = (result: SearchResult) => {
  switch (result.type) {
    case 'cassini':
      return `${result.libelle} - ${result.lineNumber} - ${result.pk}`
    case 'cassini-type':
      return `${result.libelle} - ${result.objectType} - ${result.lineNumber} - ${result.pk}`
    case 'zone':
      return result.libelle
    default:
      return 'Résultat inconnu'
  }
}

const handleHoverResult = (result: SearchResult) => {
  MapDataSignal.hoveredObjects.value = [{ properties: { ...result } }]
}

const handleUpdateContent = (result: SearchResult, data: Signal<ContentType>) => {
  const type = data?.value?.type
  if (type !== 'sch' && type !== 'geo') return
  const geom = type === 'sch' ? result.schGeom : result.geoGeom
  const vp = calculateGeometryViewport(geom, data.value.vp)

  data.value = { ...data.value, vp: { ...data.value.vp, ...vp, transitionDuration: 500 } }
}

export default function SearchResults({ results }: Props) {
  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  const handleClickResult = (result: SearchResult) => () => {
    handleUpdateContent(result, leftData)
    handleUpdateContent(result, rightData)
  }

  if (!Object.keys(results).length) {
    return <div className="search-results empty">Aucun résultat</div>
  }

  return (
    <div className="search-results">
      {Object.entries(results).map(([source, objects]) => (
        <Accordion key={source} title={SOURCE_NAMES[source] ?? 'Couche inconnue'}>
          {objects.map(object => (
            <button
              type="button"
              key={object.id}
              className="result"
              onMouseEnter={() => handleHoverResult(object)}
              onClick={handleClickResult(object)}
            >
              {renderResult(object)}
            </button>
          ))}
        </Accordion>
      ))}
    </div>
  )
}
