import { Polygon } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { CassiniSearchResult, CassiniTypeSearchResult, SearchResults, ZoneSearchResult } from 'types'
import { POSTES_LAYER_KEYS } from 'assets/layers'
import { SIGNAL_LAYERS_MNEMO } from 'pages/home/contents/map/layers'
import { isLayerSelected } from './layers'
import { CHARTIS_SOURCES_IDS, MAP_IDS_SLUGS } from './map'

const computeCassiniResults = (response: CassiniSearchResult) => response.features
  .reduce((acc: SearchResults, feature) => {
    const layer = acc[feature.properties.layer_slug] || []
    return {
      ...acc,
      [feature.properties.layer_slug]: [
        ...layer,
        {
          type: 'cassini',
          id: feature.properties.id,
          libelle: feature.properties.libelle,
          pk: feature.properties.lrs_pk,
          trackName: feature.properties.lrs_voie,
          lineNumber: feature.properties.lrs_ligne,
          schGeom: feature.properties.geom_rgi_track_sch_flat,
          geoGeom: feature.properties.geom_rgi_track_geo,
        },
      ],
    }
  }, {})

const computeCassiniTypeResults = (response: CassiniTypeSearchResult) => response.features
  .reduce((acc: SearchResults, feature) => {
    const layer = acc[feature.properties.layer_slug] || []
    return {
      ...acc,
      [feature.properties.layer_slug]: [
        ...layer,
        {
          type: 'cassini-type',
          id: feature.properties.id,
          libelle: feature.properties.libelle,
          pk: feature.properties.lrs_pk,
          trackName: feature.properties.lrs_voie,
          lineNumber: feature.properties.lrs_ligne,
          schGeom: feature.properties.geom_rgi_track_sch_flat,
          geoGeom: feature.properties.geom_rgi_track_geo,
          objectType: feature.properties.type_installation_fixe_id_mnemo,
        },
      ],
    }
  }, {})

const computeZoneResults = (response: ZoneSearchResult) => response.features
  .reduce((acc: SearchResults, feature) => {
    const layer = acc[feature.properties.layer_slug] || []
    return {
      ...acc,
      [feature.properties.layer_slug]: [
        ...layer,
        {
          type: 'zone',
          id: feature.properties.id,
          libelle: feature.properties.libelle,
          schGeom: feature.properties.geom_rgi_track_sch_flat,
          geoGeom: feature.properties.geom_rgi_track_geo,
        },
      ],
    }
  }, {})

const CASSINI_LAYERS = [
  CHARTIS_SOURCES_IDS.adv, CHARTIS_SOURCES_IDS.cdv, CHARTIS_SOURCES_IDS.jdz, CHARTIS_SOURCES_IDS.dbc,
  CHARTIS_SOURCES_IDS.passageNiveau, CHARTIS_SOURCES_IDS.pedale,
  CHARTIS_SOURCES_IDS.station, CHARTIS_SOURCES_IDS.acces, CHARTIS_SOURCES_IDS.css, CHARTIS_SOURCES_IDS.sousStation,
]

const getCassiniObjects = async (
  bbox: Polygon,
  query: string,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const layersToSearch = CASSINI_LAYERS.filter(source => {
    const layersSlugs = MAP_IDS_SLUGS[source]
    return layersSlugs.some(slug => isLayerSelected(slug))
  })
  if (!layersToSearch.length) return null
  const mainLayer = layersToSearch[0]
  const additionalLayers = layersToSearch.slice(1)
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const cassiniResponse = await get<CassiniSearchResult>(
    `/chartis/v2/layer/${mainLayer}/search/${viewParam}/`,
    {
      columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      bbox,
      additional_layers: additionalLayers.join(','),
      size_per_layer: 50,
      libelle__ilike: `%${query.trim()}%`,
      ...additionalParams,
    },
  )
  return computeCassiniResults(cassiniResponse)
}

const getPosteObjects = async (
  bbox: Polygon,
  query: string,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const posteTypes = Object.entries(POSTES_LAYER_KEYS).filter(([, value]) => isLayerSelected(value)).map(([key]) => key)
  if (!posteTypes.length) return null
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const posteResponse = await get<CassiniTypeSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.poste}/search/${viewParam}/`,
    {
      // eslint-disable-next-line max-len
      columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo,type_installation_fixe_id_mnemo',
      bbox,
      size_per_layer: 50,
      type_installation_fixe_id_mnemo__in: posteTypes.join(','),
      libelle__ilike: `%${query.trim()}%`,
      ...additionalParams,
    },
  )
  return computeCassiniTypeResults(posteResponse)
}

const getSignalsObjects = async (
  bbox: Polygon,
  query: string,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const signalTypes = Object.entries(SIGNAL_LAYERS_MNEMO)
    .filter(([key]) => isLayerSelected(key)).flatMap(([, value]) => value)
  if (!signalTypes.length) return null
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const signalResponse = await get<CassiniTypeSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.signal}/search/${viewParam}/`,
    {
      // eslint-disable-next-line max-len
      columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo,type_installation_fixe_id_mnemo',
      bbox,
      size_per_layer: 50,
      type_installation_fixe_id_mnemo__in: signalTypes.join(','),
      libelle__ilike: `%${query.trim()}%`,
      ...additionalParams,
    },
  )
  return computeCassiniTypeResults(signalResponse)
}

const getZoneObjects = async (
  query: string,
  bbox: Polygon,
  view: 'sch' | 'geo',
  params?: Record<string, string>,
) => {
  const layers = [
    ...(isLayerSelected('zoneActionPoste') ? [CHARTIS_SOURCES_IDS.zap] : []),
    ...(isLayerSelected('ihm-zone') ? [CHARTIS_SOURCES_IDS.ihm] : []),
    ...(isLayerSelected('bal-zone') ? [CHARTIS_SOURCES_IDS.bal] : []),
  ]
  if (!layers.length) return null
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const response = await get<ZoneSearchResult>(
    `/chartis/v2/layer/${layers[0]}/search/${viewParam}/`,
    {
      columns: 'id,libelle,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      bbox,
      ...(layers.length > 1 && { additional_layers: layers.slice(1).join(',') }),
      size_per_layer: 50,
      libelle__ilike: `%${query.trim()}%`,
      ...params,
    },
  )
  return computeZoneResults(response)
}

export const getBifObjects = async (
  query: string,
  bbox: Polygon,
  view: 'sch' | 'geo',
  params?: Record<string, string>,
) => {
  if (!isLayerSelected('bif')) return null
  const viewParam = view === 'sch' ? 'full_rgi_track_geo_centroid' : 'full_rgi_track_geo_centroid'
  const response = await get<CassiniSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.bif}/search/${viewParam}/`,
    {
      columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      bbox,
      size_per_layer: 50,
      libelle__ilike: `%${query.trim()}%`,
      code_ch: 'BF',
      ...params,
    },
  )
  return computeCassiniResults(response)
}

export const getRacObjects = async (
  query: string,
  bbox: Polygon,
  view: 'sch' | 'geo',
  params?: Record<string, string>,
) => {
  if (!isLayerSelected('rac')) return null
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const response = await get<CassiniSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.rac}/search/${viewParam}/`,
    {
      columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      bbox,
      size_per_layer: 50,
      libelle__ilike: `%${query.trim()}%`,
      type_ligne_id: '467e6b4c-6665-11e3-afff-01f464e0362d',
      ...params,
    },
  )
  return computeCassiniResults(response)
}

export const searchObjects = async (
  query: string,
  bbox: Polygon,
  view: 'sch' | 'geo',
  params?: Record<string, string>,
) => {
  const response = await Promise.all([
    getCassiniObjects(bbox, query, view, params),
    getPosteObjects(bbox, query, view, params),
    getSignalsObjects(bbox, query, view, params),
    getZoneObjects(query, bbox, view, params),
    getBifObjects(query, bbox, view, params),
    getRacObjects(query, bbox, view, params),
  ])
  const searchResults = response.reduce((acc: SearchResults, result) => {
    if (!result) return acc
    return { ...acc, ...result }
  }, {})
  return searchResults
}
