import { SOURCE_NAMES } from 'services'
import { LayerFeature } from 'types'
import { MapDataSignal, PanelSignal } from '../PanelsManager'

type Props = {
  data: LayerFeature[]
  onSelect: (d: LayerFeature) => void
}

export default function ObjectList({ data, onSelect }: Props) {
  PanelSignal.title.value = `Objets superposés (${data?.length})`
  const sources = data?.map(d => d.source).filter(
    (value, index, self) => self.indexOf(value) === index && Object.keys(SOURCE_NAMES).includes(value),
  )

  const handleSelect = (feature: LayerFeature) => () => { onSelect(feature) }
  const handleHover = (features: LayerFeature[]) => () => { MapDataSignal.hoveredObjects.value = features }

  return (
    <div className="objects-list">
      {sources?.map(source => (
        <div key={source}>
          <p className="source">{SOURCE_NAMES[source] ?? source}</p>
          {data?.filter(d => d.source.includes(source)).map(d => (
            <div
              key={d.properties.id}
              className="data"
              onClick={handleSelect(d)}
              onMouseEnter={handleHover([d])}
              onMouseLeave={handleHover([])}
            >
              <p>{d.properties.libelle || 'N/C'}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
