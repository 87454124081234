/* eslint-disable no-console */
import terms from 'assets/terms'
import { Button, ButtonStyle, ButtonSize, ReportModal, ModalSignal } from 'components'
import ToolWrapper from './ToolWrapper'

type Props = {
  onClose: () => void
}

export default function ContactTool({ onClose }: Props) {
  const contact = [
    {
      label: terms.ToolsPanel.contact.suggest,
      onClick: () => {
        ModalSignal.value = <ReportModal type="Suggérer une amélioration" />
      },
      enable: true,
    },
    {
      label: terms.ToolsPanel.contact.bug,
      onClick: () => {
        ModalSignal.value = <ReportModal type="Remonter un bug" />
      },
      enable: true,
    },
  ]

  return (
    <ToolWrapper title={terms.ToolsPanel.contact.title}>
      {contact.map(tool => (
        <Button
          key={tool.label}
          style={ButtonStyle.borderLess}
          text={tool.label}
          size={ButtonSize.medium}
          onClick={() => {
            tool.onClick()
            onClose()
          }}
          disabled={!tool.enable}
        />
      ))}
    </ToolWrapper>
  )
}
